import axios from 'axios';
import { ref } from 'vue';

const getSearchResults = () => {
    const searchResults = ref([]);
    const loading = ref(false);

    const loadSearchResults = (search) => {
        loading.value = true;
        if (search.length > 2) {
            axios.get(`https://plex.nzbhorizon.com/search?query=${search}&X-Plex-Token=${process.env.VUE_APP_X_Plex_Token}`)
                .then(res => {
                    loading.value = false;
                    if (res.data.MediaContainer.Metadata) {
                        res.data.MediaContainer.Metadata.forEach(t => {
                            t.X_Plex_Token = process.env.VUE_APP_X_Plex_Token
                          })
                        searchResults.value = res.data.MediaContainer.Metadata.filter(r => { return (r.type === 'movie' || r.type === 'show')});
                    } else {
                        searchResults.value = []
                    }
                }).catch(err => {
                    loading.value = false;
                    console.log(err);
                });
        } else {
            searchResults.value = [];
        }
    }

    return { searchResults, loading, loadSearchResults };
}

export default getSearchResults;
