require('dotenv').config()
import { createApp } from 'vue'
import { createStore } from "vuex"
import App from './App.vue'
import router from './router'

import VueClickAway from "vue3-click-away";

const store = createStore({
  state: {
    movies: [],
    tvShows: [],
    anime: [],
    music: []
  },
  mutations: {
    setMovies(state, data) {
      state.movies = data
    },
    setTvShows(state, data) {
      state.tvShows = data
    },
    setAnime(state, data) {
      state.anime = data
    },
    setMusic(state, data) {
      state.music = data
    },
  }
})

createApp(App).use(VueClickAway).use(router).use(store).mount('#app')
