<script>
  import { watch, ref } from "vue";
  import getSearchResults from "./composables/getSearchResults";
  import sliceArray from "../composables/sliceArray";
  import VLazyImage from "v-lazy-image"

  export default {
    components: { VLazyImage },
    props: ["search"],
    setup(props) {
      const { searchResults, loadSearchResults } = getSearchResults();
      const timeOut = ref(setTimeout(function () {}, 500))
      watch(() => props.search, (search) => {
        clearTimeout(timeOut.value)
        timeOut.value = setTimeout(function () {
          loadSearchResults(search)
        }, 500)
      })

      return { searchResults, sliceArray };
    },
  };
</script>

<template>
  <div
    v-if="search.length > 2"
    class="absolute z-50 w-64 mt-4 text-sm bg-gray-500 rounded dark:bg-gray-800 text-mywhite"
  >
    <ul v-if="searchResults.length">
      <li
        class="border-b border-gray-600 dark:border-gray-700"
        v-for="result in sliceArray(searchResults, 0, 7)"
        :key="result"
      >
        <router-link
          v-if="result.librarySectionID === 1"
          :to="{ name: 'PlexMovie', params: { id: result['ratingKey'] } }"
          class="flex items-center px-3 py-3 transition duration-150 ease-in-out hover:bg-gray-700"
        >
          <v-lazy-image :src="`https://plex.nzbhorizon.com/photo/:/transcode?width=45&height=68&minSize=1&upscale=1&url=${result.thumb}&X-Plex-Token=${result.X_Plex_Token}`" src-placeholder="https://via.placeholder.com/45x68" class="transition ease-in-out duration-400 hover:opacity-80" alt="poster"/>
          <span class="ml-4">{{ result["title"] }} <br><small>{{ result["librarySectionTitle"] }}</small></span>
        </router-link>
        <router-link
          v-else-if="result.librarySectionID === 2"
          :to="{ name: 'PlexTvShow', params: { id: result['ratingKey'] } }"
          class="flex items-center px-3 py-3 transition duration-150 ease-in-out hover:bg-gray-700"
        >
          <v-lazy-image :src="`https://plex.nzbhorizon.com/photo/:/transcode?width=45&height=68&minSize=1&upscale=1&url=${result.thumb}&X-Plex-Token=${result.X_Plex_Token}`" src-placeholder="https://via.placeholder.com/45x68" class="transition ease-in-out duration-400 hover:opacity-80" alt="poster"/>
          <span class="ml-4">{{ result["title"] }} <br><small>{{ result["librarySectionTitle"] }}</small></span>
        </router-link>
        <router-link
          v-else-if="result.librarySectionID === 4"
          :to="{ name: 'PlexAnimeShow', params: { id: result['ratingKey'] } }"
          class="flex items-center px-3 py-3 transition duration-150 ease-in-out hover:bg-gray-700"
        >
          <v-lazy-image :src="`https://plex.nzbhorizon.com/photo/:/transcode?width=45&height=68&minSize=1&upscale=1&url=${result.thumb}&X-Plex-Token=${result.X_Plex_Token}`" src-placeholder="https://via.placeholder.com/45x68" class="transition ease-in-out duration-400 hover:opacity-80" alt="poster"/>
          <span class="ml-4">{{ result["title"] }} <br><small>{{ result["librarySectionTitle"] }}</small></span>
        </router-link>
      </li>
    </ul>
    <div class="px-3 py-3" v-else>No results for "{{ search }}"</div>
  </div>
</template>

<style>
</style>
